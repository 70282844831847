import React from 'react';

const Footer = () => (
  <footer className="footer">
    <div className="footer__call column">
      <span className="footer__text footer__text--bold">Deu curiosidade? </span>
      <span className="footer__text">Aqui também, vamos conversar!</span>
      <span className="footer__text footer__text--highlight">&nbsp;:)</span>
      <div className="footer__email">
        <a aria-label="Entre em contato com a gente" href="mailto:contato@kusudama.com.br">contato@kusudama.com.br</a>
      </div>
    </div>

    <div className="footer__media column is-3">
      <a aria-label="acesse nosso Twitter" className="footer__media-icon" target="_blank" rel="noopener noreferrer" href="https://twitter.com/kusudamadesign">
        <svg className="footer__media-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
          <path d="M30 2.824a12.436 12.436 0 0 1-3.528.974 6.086 6.086 0 0 0 2.696-3.36 12.51 12.51 0 0 1-3.9 1.482C24.135.706 22.543 0 20.736 0c-3.37 0-6.123 2.71-6.123 6.056 0 .48.057.946.158 1.384A17.58 17.58 0 0 1 2.094 1.115a5.921 5.921 0 0 0-.832 3.036 5.936 5.936 0 0 0 2.739 5.025c-1.018 0-1.965-.282-2.796-.705v.042c0 2.936 2.122 5.393 4.933 5.943a6.142 6.142 0 0 1-2.768.1 6.126 6.126 0 0 0 5.736 4.206C7.012 20.4 4.36 21.36 1.463 21.36c-.488 0-.975-.028-1.463-.085A17.615 17.615 0 0 0 9.436 24c11.3 0 17.51-9.233 17.51-17.238 0-.268 0-.522-.015-.79A12.187 12.187 0 0 0 30 2.824z"/>
        </svg>
      </a>
      <a aria-label="acesse nosso Instagram" className="footer__media-icon" target="_blank" rel="noopener noreferrer" href="https://instagram.com/kusudamadesign">
      <svg className="footer__media-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <path d="M8.685 0h12.58c4.791 0 8.685 3.9 8.685 8.7v12.6c0 4.805-3.889 8.7-8.686 8.7H8.685C3.893 30 0 26.1 0 21.3V8.7C0 3.895 3.889 0 8.685 0zm-.3 3a5.395 5.395 0 0 0-5.39 5.4v13.2c0 2.985 2.41 5.4 5.39 5.4h13.179a5.395 5.395 0 0 0 5.39-5.4V8.4c0-2.985-2.41-5.4-5.39-5.4H8.386zm14.452 2.25c1.033 0 1.872.84 1.872 1.875A1.873 1.873 0 0 1 22.837 9a1.873 1.873 0 0 1-1.872-1.875c0-1.036.838-1.875 1.872-1.875zM14.975 7.5c4.135 0 7.487 3.358 7.487 7.5 0 4.142-3.352 7.5-7.487 7.5S7.487 19.142 7.487 15c0-4.142 3.353-7.5 7.488-7.5zm0 3a4.496 4.496 0 0 0-4.493 4.5c0 2.485 2.012 4.5 4.493 4.5a4.496 4.496 0 0 0 4.492-4.5c0-2.485-2.011-4.5-4.492-4.5z"/>
      </svg>

      </a>
      <a aria-label="acesse nosso LinkedIn" className="footer__media-icon" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/kusudama">
        <svg className="footer__media-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
          <path d="M26.667 0A3.333 3.333 0 0 1 30 3.333v23.334A3.333 3.333 0 0 1 26.667 30H3.333A3.333 3.333 0 0 1 0 26.667V3.333A3.333 3.333 0 0 1 3.333 0h23.334zm-.834 25.833V17c0-3-2.432-5.433-5.433-5.433-1.417 0-3.067.866-3.867 2.166v-1.85h-4.65v13.95h4.65v-8.216a2.326 2.326 0 0 1 2.317-2.334 2.333 2.333 0 0 1 2.333 2.334v8.216h4.65zM6.467 9.267a2.8 2.8 0 0 0 2.8-2.8 2.81 2.81 0 0 0-2.8-2.817A2.817 2.817 0 0 0 3.65 6.467a2.81 2.81 0 0 0 2.817 2.8zm2.316 16.566v-13.95H4.167v13.95h4.616z"/>
        </svg>
      </a>
    </div>
  </footer>
);

export default Footer;