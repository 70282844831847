import React from "react"
import PropTypes from "prop-types"

import "./layout.scss"
import Header from "./header"
import Footer from "./footer";

const Layout = ({ children }) => (
  <main className="main">
    <Header />
    <div className="content">{children}</div>
    <Footer />
  </main>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
