import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typed from 'typed.js'

class IndexPage extends React.Component {
  componentDidMount() {
    const options = {
      strings: [
        'desenvolver experiências de qualidade para Android e iOS.',
        'desenhar experiências de qualidade para Android e iOS.',
        'aprender sobre Design Sprint e como aplicá-lo no dia a dia.',
        'se aperfeiçoar através de treinamentos sobre mobile.',
      ],
      typeSpeed: 50,
      backSpeed: 30,
      loop: true,
    };

    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[`design`, `desenvolvimento`, `consultoria`, `mobile`, `design sprint`, `sprint`, `android`, `ios`, `experiencia`, `treinamentos`, `workshops`]} />
        <section className="intro">
          Nós ajudamos você e sua empresa a&nbsp;
          <span
            className="intro__highlight"
            ref={(el) => { this.el = el; }}
          >
          </span>
        </section>
      </Layout>
    )
  }
};

export default IndexPage
