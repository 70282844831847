import React from "react"
import logo from "../images/logo.svg"
import { Link } from 'gatsby';

const Header = () => (
  <header className="header columns">
    <div className="header__logo column is-3">
      <Link aria-label="Voltar para home" to='/'><img src={logo} alt="Kusudama Logo" /></Link>
    </div>

    <nav className="header__nav column" role="navigation" aria-label="main navigation">
      <Link aria-label="Ir para Serviços" className="header__link" activeClassName="header__link--active" to="/servicos">Serviços</Link>
      <Link aria-label="Ir para Sobre" className="header__link" activeClassName="header__link--active" to="/sobre">Sobre</Link>
    </nav>
  </header>
);

export default Header
